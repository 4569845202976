@import 'src/variables.scss';

.Promo {
  padding-top: 3rem;

  &__cards-container {
    margin-bottom: 4rem;
  }
}

@media (max-width: 700px) { 
  .Promo {
    &__cards {
      .mobile-card {
        display: flex;
        justify-content: center;

        &:not(:last-child) {
          margin-bottom: 1em;
        }
      }
    }
  }
}