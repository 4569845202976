@import 'src/variables.scss';

.Form {
  background-color: $white;
  box-shadow: 0px 5px 10px #00000024;
  border-radius: 5px;
  padding: 1.5em;
  color: $dark-red;
  position: relative;
  right: 15%;

  &__title {
    font-family: Museo700;
  }
  
  &__input {
    width: 100%;
  }

  &__button {
    display: flex;
    justify-content: end;
    margin-top: 2rem;
  }
}

@media (max-width: 700px) { 
  .Form {
    position: unset;
    right: unset;
  }
}