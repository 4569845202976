@import 'src/variables.scss';

.Welcome {
    display: flex;
    background-color: $salmon;
    height: 90vh;
     @media (max-width: 700px) {
       height: 80vh;
     }

    .swiper-wrapper {
      display: flex;
      width: 100%;
    }

    .swiper-container {
      position: relative;

      .swiper-wrapper {
        height: 100%;
        scroll-behavior: smooth;
        overflow: hidden;
      }

      .swiper-slider {
        width: 100%;
        flex: 0 0 auto;
      }

      .swiper-next {
        position: absolute;
        bottom: 10%;
        right: 10%;
        display: flex;
        align-items: center;
        z-index: 2;

        .right-arrow {
          height: 20px;
          padding-right: 1em;
          cursor: pointer;
        }

        .counter {
          color: $white;
          display: flex;
          align-items: baseline;

          p {
            margin: 0;

            &:first-child {
              font-size: 1.5rem;
            }
          }
        }
      }
    }

    &__row {
      height: 100%;
      align-items: center;
    }

    &__description {
        color: $white;

        .title {
            font-size: 3em;
            @media (max-width: 700px) {
              font-size: 2em;
            }
            font-family: Museo700;
            margin-bottom: 0.75em;
            width: 80%;
            white-space: break-spaces;
        }
    }

    &__gallery {
        background-size: cover;
        position: absolute;
        width: 41.66667%;
        top: 0;
        right: 0;
        height: 90vh;
        background-position: center;
    }
}

@media (max-width: 700px) { 
  .Welcome {
    &__gallery {
      display: none;
    }

    .swiper-container {
      width: 100%;
    }
  }
}