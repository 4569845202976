.item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .logo-container {
    height: 80px;
    width: 80px;
    border-radius: 100%;
    background-color: #F7E2D2;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;

    &__image {
      width: 40px;
      height: 40px;
    }
  }

  .text {
    font-size: 12px;
    margin-bottom: 0.5em;
  }
}