@import 'src/variables.scss';

.BookingFilterCommon {
  &__wrapper {
    background-color: $white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    padding: 1em;
    z-index: 2;
  
    .border-right {
      border-right: 1px solid $salmon;
    }
  }
  
  &__select {
    border: none;
    color: inherit;
    font-size: 12px;
    width: unset;
    background-image: url('../../../images/down-arrow.svg');
    background-size: 12px;
    cursor: pointer;
  
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  
  &__filter {
    color: $salmon;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  
    .image {
      width: 35px;
      height: 35px;
    }
  }
  
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 700px) {  
  .BookingFilterCommon { 
    &__wrapper {
      .border-right {
        border-right: none;
      }
    }

    &__button {
      justify-content: center;
    }
  }
}