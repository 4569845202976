@import 'src/variables.scss';

.FollowingButton {
  background-color: $dark-red;
  font-size: 14px;
  color: $white;
  border: 0;
  padding: 0.5em 1em;
  font-family: Museo700;
  position: absolute;
  right: 0;

  &__logo {
    width: 20px;
    height: 20px;
    margin-right: 1em;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }
}