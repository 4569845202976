@import 'src/variables.scss';

.Datepicker {
  display: flex;
  align-items: center;
  justify-content: center;

  &__button {
    padding: 0.375rem 0.75rem;
    background-color: transparent;
    border: none;
    color: $salmon;
    font-size: 12px;
  }

  &__arrow {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

}

.day {
  color: $salmon !important;
}

.react-datepicker {
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, 
  .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected {
      background-color: $dark-red !important;
      color: $white !important;
  }

  &__header {
    background-color: $salmon !important;

    .react-datepicker__current-month {
      color: $white;
    }

    .react-datepicker__day-names {
      color: $white;

      .react-datepicker__day-name {
        color: $white;
      }
    }
  }
}