@import 'src/variables.scss';

.AboutUs {
  position: relative;
  padding-bottom: 3.5rem;
  height: 480px;

  .button {
    background-color: $dark-red;
    font-size: 14px;
    color: $white;
    border: 0;
    padding: 0.5em 1em;
    font-family: Museo700;
    position: absolute;
    right: 0;
    top: -48.5px;

    &__logo {
      width: 20px;
      height: 20px;
      margin-right: 1em;
    }

    &__wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__image {
    background-image: url("../../images/about-us.jpg");
    width: 450px;
    height: 450px;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    position: absolute;
    left: 7%;
    top: 5%;
    z-index: 1;
  }

  &__square {
    height: 75px;
    position: absolute;
    bottom: 25%;
    left: -1%;
  }
}

@media (max-width: 700px) { 
  .AboutUs {
    &__image {
      width: 100%;
      position: unset;
    }

    &__square {
      display: none;
    }
  }
}