@import 'src/variables.scss';

.NavbarLight {
    background: white;
    padding: 1rem 0;
    width: 100%;

    &__logo {
        .logo {
            height: 60px;
            cursor: pointer;
        }
    }

    &__links {
        flex-grow: 0;
    }

    &__link {
        color: $dark-red !important;
        font-weight: 700;
        margin-right: 20px;
        position: relative;

        &::after {
            content: '';
            display: block;
            width: 0%;
            height: 2px;
            background: $dark-red;
            transition: width .3s;
        }

        &:hover::after {
            width: 80%;
        }
    }

    &__wsp {
        color: $dark-red;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 40px;

        .icon {
            height: 20px;
            margin-right: 10px;
        }

        .number {
            margin-bottom: 0;
            font-weight: 700;
            color: $dark-red;
        }
    }

}
