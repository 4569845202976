@import 'src/variables.scss';

.KnowUs {
    background: $salmon-secondary;
    padding: 120px 0;
    &__title {
        font-size: 30px;
        color: $dark-red;
        font-weight: 700;
    }

    &__desc {
        font-weight: 300;
        color: $gray;
        font-size: 20px;
        width: 80%;
        margin-top: 40px;
    }
}