@import 'src/variables.scss';

.OurServices {
  background-color: $salmon-secondary;
  padding: 5rem 0 3rem 0;
  position: relative;

  &__items-section {
    margin-top: 1.5rem;
  }

  &__item {
    display: flex;
  }

  &__logo {
    justify-content: center;
    position: relative;

    .image {
      width: 170px;
      height: 90px;
      position: absolute;
      top: 4px;
    }
  }
}