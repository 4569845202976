@import 'src/variables.scss';

.TopBanner {
    height: 300px;
    width: 100%;
    position: relative;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    &__gradient {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, .9) 0, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%);
    }
    &__title {
        font-family: Museo700;
        font-weight: 700;
        font-size: 70px;
        color: $white;
        position: absolute;
        text-align: center;
    }
}

@media (max-width: 700px) {  
  .TopBanner {
    &__title {
      font-size: 41px;
    }
  }
}