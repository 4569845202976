@import 'src/variables.scss';

.Card {
  box-shadow: 0px 5px 10px #00000024;
  border-radius: 5px;
  width: 90%;
  font-size: 12px;
  background-color: $white;

  &:hover {
    background-color: $salmon-secondary;
  }

  &__image {
    height: 150px;
    width: 100%;
    background-size: cover;
    border-radius: 5px 5px 0 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2em;

    .type {
      font-family: Museo700;
      border-bottom: 1px solid;
      padding: 1em 0 1.25em 0;
    }

    .room {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;

      &__price {
        font-size: 16px;
        font-family: Museo700;
      }

      &__service {
        margin-bottom: 0.5em;
      }
    }
  }

  &__button {
    padding: 1em 0 2em 0;
  }
}

@media (max-width: 700px) { 
  .Card {
    width: 90%;
  }
}