@import '~react-image-gallery/styles/scss/image-gallery.scss';

.image-gallery-image {
  border-radius: 3px;
}

.image-gallery-thumbnail {
  border-radius: 3px;
  transition: none;
  &:hover {
      outline: none;
      border: 0;
      transition: none;
  }

  &:active {
      border: 0;
  }
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none;
  border: none;
}

.image-gallery-thumbnail-image {
  border-radius: 3px;
}

.image-gallery-svg {
  height: 40px !important;
}

.image-gallery-icon:hover {
  color: $white;
}