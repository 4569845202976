@import 'src/variables.scss';

.BookingFilter {
  width: 67%;
  background-color: $white;
  position: relative;
  bottom: 48.5px;
  border-radius: 7px;
  z-index: 2;
}

@media (max-width: 700px) { 
  .BookingFilter {
    width: 100%;
    button {
      margin: 0.5rem 0;
    }
  }
}