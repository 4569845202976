@import 'src/variables.scss';
@import 'src/globals.scss';

.Gallery {
    padding: 120px 0;
    background: $salmon-secondary;

    &__title {
        font-size: 30px;
        color: $dark-red;
        font-weight: 700;
    }

    &__desc {
        display: flex;
        height: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 14px;
        p {
            font-weight: 300;
            color: $gray;
            font-size: 18px;
            width: 80%;
        }
    }

    &__wrapper {
        margin: 40px 0;
    }
}