@import 'src/variables.scss';

.Contact {
  
  &__info {
    padding: 4rem 0;
    color: $white;
    background-color: $salmon;
    position: relative;

    .title {
      font-family: Museo700;
      margin-bottom: 0.5em;
    }

    .contact-data {
      font-size: 16px;
      
      &__title {
        font-size: 16px;
        font-family: Museo700;
      }

      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        .icon-image {
          height: 24px;
          color: $white;
        }

        .icon {
          font-size: 24px;
        }

        .svg-inline--fa.fa-w-12 {
          width: 1em;
        }

        .text {
          margin: 0;
          padding-left: 1em;
        }
      }
    }

    .social-media {
      margin-top: 2em;

      &__title {
        font-size: 16px;
      }

      &__icons {
        .icon {
          font-size: 24px;

          &:first-child {
            margin-right: 0.5em;            
          }
        }
      }
    }

  }

  &__form {
    background-color: $salmon-secondary;
    position: absolute;
    top: 0;
    right: 0;
    padding: 48px 1rem;
  }

  &__location {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: $salmon-secondary;
    color: $dark-red;

    .title {
      font-family: Museo700;
    }

    .alignment {
      justify-content: space-between;
      align-items: center;
    }
  }

  &__map {
    height: 360px;
    width: 100%;
  }

  .marker {
    color: $dark-red;
    font-size: 24px;
  }
}

@media (max-width: 700px) { 
  .Contact {
    
    &__form {
      position: relative;
      top: 64px;
    }
  }
}