@import 'src/variables.scss';

.Button {
  border-radius: 5px;
  font-size: 14px;
  color: $white;
  padding: 0.5em 1em;
  font-family: Museo;
  
  &--primary, &--submit {
    border: 0;
    background-color: $dark-red;
  }

  &--secondary {
    color: $salmon;
    border: 1px solid $salmon;
    background-color: $white;
  }

  &--secondary-dark {
    color: $white;
    background-color: $salmon;
    border: 1px solid #BD9E81;
  }

  &--sides-padding {
    padding: 0.5em 3em;
  }
}