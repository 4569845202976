.GuestComments {
  padding: 6rem 0;

  &__title {
    display: flex;
    align-items: center;

    .title {
      padding-right: 0.5em;
       @media (max-width: 700px) {
         font-size: 1.5rem;
       }
    }

    .star {
      height: 23px;
      margin-bottom: 1.5rem;
      padding: 0 0.5rem;
    }
  }

  &__image {
    display: flex;

    .image {
      background-image: url('../../images/guest-comments.jpg');
      width: 100%;
      height: 300px;
      background-size: cover;
      border-radius: 5px;
      background-position-y: center;
      z-index: 1;
    }

    .square {
      width: 85px;
      height: 85px;
      background-image: url('../../images/mask-group.png');
      background-size: cover;
      position: relative;
      top: 180px;
      right: 6%;
    }
  }

  &__comments {
    position: relative;
    bottom: 20px;

    .arrows {
      display: flex;
      justify-content: flex-end;

      .arrow {
        font-size: 20px;
        padding: 0.2em 0.5em;
        border-radius: 3px;

        &:first-child {
          margin-right: 0.75rem;
        }
      }
    }

    .quotes {
      height: 40px;
    }

    .comments {
      margin-top: 1.5rem;

      &__comment {
        min-height: 48px;
      }

      &__person {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
        align-items: center;

        .avatar {
          height: 60px;
          width: 60px;
          background-position: center;
          background-size: cover;
          border-radius: 100%;
        }

        .info {
          margin-left: 1rem;

          .text {
            margin: 0;
          }

          &__name {
            font-family: Museo700;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) { 
  .GuestComments {    
    &__comments {
      padding-top: 2rem;
      position: unset;
      bottom: unset;
    }
  }
}