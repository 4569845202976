@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: Museo;
  src: url('./fonts/MUSEO300-REGULAR.OTF');
}

@font-face {
  font-family: Museo700;
  src: url('./fonts/MUSEO700-REGULAR.OTF');
}

.App {
  font-family: Museo;

  a {
    text-decoration: none;
    color: $white;
  }
}