@import 'src/variables.scss';

.mat-label {
  color: $dark-red;
  display: block;
  font-size: 16px;
  transform: translateY(30px);
  transition: all 0.5s;
}

.mat-input {
  position: relative;
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 0;
  font-size: 16px;
}

.mat-div {
  position: relative;

  &__wrapper {
    margin-bottom: 1em;
  }
}

.mat-div:after, .mat-div:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background-color: $salmon-secondary; 
  bottom: 0;
  left: 0;
  transition: all 0.5s;
}

.mat-div::after {
  background-color: $dark-red;
  transform: scaleX(0);
}

.is-active::after {
  transform: scaleX(1);
}

.is-completed .mat-label {
  font-size: 12px;
  transform: translateY(0);
}