.RoomCards {
  padding: 2.5rem 0 3.5rem 0;
}

@media (max-width: 700px) {  
  .mobile-card {
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
}