  @import 'src/variables.scss';

.Room {
  &__data {
    background-color: $salmon-secondary;
    position: relative;
  }

  &__filter {
    justify-content: center;
    padding-top: 3rem;
  }

  &__services {
    padding: 6rem 0;
    align-items: center;

    .services-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      &__item {
        padding-top: 1.5em;
        flex: 0 0 auto;
        width: 50%;
      }
    }

    .gallery {
      font-size: 30px;
      color: $dark-red;
      font-weight: 700;
      margin-bottom: 40px;
    }
  }

  &__protocol {
    background-color: $white;
    padding: 2.5rem 0;

    .align-items {
      align-items: center;
      justify-content: space-between;
    }
  }

  &__cards {
    padding-top: 3.5rem;
  }
}

@media (max-width: 700px) {  
  .Room {
    &__protocol {
      .padding-m {
        padding: 1em 0;
      }
    
      .align-items {
        justify-content: center;
      }

      .button {
        display: flex;
        justify-content: center;
      }
    }
  }
}