.TextContainer {
  .title {
    margin-bottom: 1.5rem;
    font-size: 2rem;
  }

  .separator {
      margin: 0;
      width: 30%;
      border-color: rgba(205, 206, 205, 0.5);
  }

  .first-paragraph {
    margin-top: 1rem;
  }
}