@import 'src/variables.scss';

.Inquiry {
  background-image: url('../../../images/HotelLQ-2.jpg');
  height: 230px;
  background-size: contain;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .background {
    background-color: #610A20;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.68;
  }

  .text {
    z-index: 1;
    color: $white;
    cursor: pointer;
    display: flex;

    &__title {
      &::after {
        content: '';
        display: block;
        width: 0%;
        height: 2px;
        background: $white;
        transition: width .3s;
      }
      
      &:hover::after {
        width: 80%;
      }
    }

    &__logo {
      margin-left: 1.5rem;
    }
  }
}

@media (max-width: 700px) {
  .Inquiry {  
    .text {
      align-items: center;
      
      &__title {
        margin-bottom: 0;
        font-size: 20px;
      }
    }
  }
}