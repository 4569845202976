@import 'src/variables.scss';

.GreenStamp {
    background: $white;
    padding: 80px 0px;
    &__logo {
        height: 80px;
    }
    &__items {
        padding: 40px 0;
        color: $salmon;
    }
}