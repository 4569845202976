@import 'src/variables.scss';

.Footer {
  background: $dark-red;
  padding: 60px 0;

  &__logo {
    height: 70px;
    width: 70px;
  }

  &__items {
    color: $white;
    list-style: none;
    padding-left: 0;
    margin: 0;

    li {
      margin: 15px 0;

      img {
        height: 28px;
        width: 28px;
        margin-right: 10px;
      }
    }

    .dataFiscal {
      height: 130px;
    }
  }

  a {
    &:hover {
      color: $salmon;
    }
  }

}

.Copy {
  background: #610A20;
  padding: 10px 0;
  p {
    margin-bottom: 0;
    color: $white;
  }

  small {
    color: $white;
  }
}

@media (max-width: 700px) {
  .Copy {
    font-size: 14px;
    .container {
      flex-direction: column;
    }
  }
}